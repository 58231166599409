/* YourComponent.css */

.patch-Question {
    margin-top: 130px;
    display: flex;
    justify-content: center;
  align-items: center;
    height: 80vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-blend-mode: multiply;
    background-image: url('/public/assets/patch-Question.webp'); /* Replace with your image path */
  
    
  }
  .btn-primary{
    border-color: #54429A;
  }
  
  
/* Add media queries for different screen sizes */
@media screen and (max-width: 767px) {
.patch-Question {
    height: 35vh;
}
.quest-cont{
    margin-top:40px;
}
.quest-cont .btn{
    margin-top: 20px;
}
.quest-cont h1{
    
    font: size 24px;
}
.quest-cont p{
    
    font-size:medium;
}
.quest-cont a{
    
    font-size: 14px;
    width:max-content;
}
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
    .patch-Question {
        height: 35vh;
    }
}

@media screen and (min-width: 1024px) {
    .patch-Question {
        height: 80vh;
    }
    .quest-cont .btn{
        margin-top: 20px;
    }
}
  
/* .patch-Question{
    height: 80vh;
    background-size: cover;
    background-repeat: no-repeat;
  background-blend-mode: multiply;
    background-image: url('/public/assets/patch-Question.webp');
} */

    /* clip-path: polygon(22% 0, 100% 19%, 100% 100%, 0 100%, 0 19%); */
