.aa {
  background-color: #121315;
}

.dash-nav a {
  display: flex;
  align-items: center;
  text-align: left;
  text-decoration: none;
  margin-right: 20px;
  color: var(--black, #121315);
}

.dash-nav a:hover {
  /* text-decoration: underline; */
  color: #ffffff;
}

.dash-nav a:active {
  /* text-decoration: underline; */
  color: #ffffff;
}


.dash-nav a {
  color: #000000;
  font-size: 16px;
  text-transform: uppercase;
  display: inline-block;
  padding: 10px 15px;
  position: relative;
}

.dash-nav a::before {
  content: '';
  display: block;
  height: 3px;
  background-color: #ffffff; 
  position: absolute;
  bottom: 0;
  width: 0%;
  transition: all 0.3s ease-in-out;
}

.dash-nav a:hover::before {
  width: 100%;
}

.dash-nav .nav-item:hover a {
  color: #000000; /* Apply hover text color */
}