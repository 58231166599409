.v-d-cntainer{
    margin-top: 5vh;
    margin-bottom: 10vh;
}
.v-cont{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 500px;
    height: 500px;
    text-align: start;
    font-size: 1.5rem;
}
.v-cont p{
    /* font-size: larger; */
}
.v-gellary{
    width: 42rem;
    height: 28rem;
}
@media only screen and (min-width: 768px) {
    .mob-v-imag{
        display: none;
    }

}
@media only screen and (max-width: 768px) {
    .v-d-cntainer{
        margin-bottom: 45vh;
    }
    .desk-v-gel{
        display: none;
    }
    .mob-v-imag img{
        margin-top: 20px;
        width: 330px;
        height: 330px;
    }
    .v-cont{
        width: 381px;
        height: 100px;
        text-align: center;
        font-size: 14px;
    }
    .v-gellary{
        border-radius: 20px !important;
    }
  }