.foter{
height: 130px;
display: flex;
flex-direction: column;
}
.foter .h6{
    color: #ffffff8b;
    
}
@media only screen and (max-width: 768px){
    .foter img{
        width: 25px;
        height: 25px;
    }
    .foter .h6{
        font-size: 10px;
    }
}