        /* <<<<<<<<<HEADER>>>>>>>>> */

.c-Header{
    background-image: url("/public/assets/ContactHeader.webp"),linear-gradient(180deg, rgba(21, 45, 64, 0.00) 0%, rgba(30, 25, 78, 0.46) 25.43%, #625f83 100%);
    background-size: cover;
    background-repeat: no-repeat;
    background-blend-mode: multiply !important;
    clip-path: polygon(50% 0%, 100% 0, 100% 90%, 78% 100%, 0 90%, 0 0);
    position: relative !important;
    z-index: 1 !important;
  }
  /* .c-Header.shrink{
      height: 200px;
  } */
  .c-Header-contnt{
      display: flex !important;
      flex-direction: column !important;
      align-items: center !important;
      justify-content: center !important;
      position: relative !important;
      top: 10vh !important;
  }


/* <<<<<<<<<FORM & MAP>>>>>>>>> */
.cf-cntainer{
    width: 65vw !important;
    margin-top: 10vh !important;
    margin-bottom: 50px !important;
    position: relative !important;
    z-index: 2 !important;
}
.cf-form{
  background-color: #ffff !important;
  border-radius: 30px 0px 0px 30px !important;
  width:100% !important;
  height: 100% !important;
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.248) !important;


}
.cf-map{
  border-radius: 0px 30px 30px 0px !important;
  width: 100% !important;
  height: 100% !important;
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.248) !important;

}



  /* Add media queries for different screen sizes */
  @media (max-width: 991.98px) {
    .c-Header{
      height:auto !important;
    padding-bottom: 40px !important;
    margin-bottom: 12vh !important;
    }
    .c-Header-contnt{
      top: 0vh !important;
    }
    .discord img{
      display: none !important;
    }
    .cf-cntainer{
      margin-top: -7vh !important;
      width: 99vw !important;
    }
    .cf-lft{
      padding: 10px !important;
    }
    .form-h-cont{
      text-align: center !important;
    }
    .cf-form{
      box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.248) !important;
      border-radius: 30px !important;
    }
    .address-raow a{
      margin-top: 20px !important;
    }
    .address-raow span{
      font-size: 12px !important;
    }
    .cf-rght{
      padding: 10px !important;
    }
    .cf-map{
      box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.207) !important;
      border-radius: 30px !important;
      height: 50vh !important;
    }
   }
  
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    .c-Header{
    height: auto;
    }
    .discord img{
      display: none;
    }
    .cf-cntainer{
      width: 85vw;
  }
  }
  
  @media screen and (min-width: 1024px) {
    .c-Header{
      height: 50vh;
    }
    .cf-cntainer{
      width: 65vw;
  }
  
  }