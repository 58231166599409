.nav-container{
    margin-bottom: -6.6rem;
    /* position: absolute;
      top: 0;
      left: 0; */
      z-index: 2;
}
.aa{
    background-color: #121315 !important;
}
.nav-links a, .LR{
    text-decoration: none !important;
    margin-right: 20px !important;
    color: var(--black, #121315) !important;
}
.nav-links a:hover, .LR:hover{
 text-decoration: underline !important;
 color:#0a58ca !important;
}
.nav-links a:active, .LR:active{
    text-decoration: underline !important;
    color: #54429A !important;
   }

.btn-primary{
    background-color:#54429A !important;
    width: 150px !important;
    border-radius: 25px !important;
}
@media screen and (max-width: 992px) {
    .logoImg{
        width: 70px !important;
        height: 50px !important;
        
    }
    .nav-container a, .LR{
        color: #F9F9F9 !important;
    }
    .collapse{
        box-shadow:0px 0px 15px 10px #0101013d !important;
        padding: 20px !important;
        margin-top: 10px !important;
        background-color: var(--secondary, #3198D4) !important;
    }
    .collapse button{
        
    }
    .mob-nav-epend a, .LR{
        border-bottom: 1px solid rgba(255, 255, 255, 0.355) !important;
        padding: 15px !important;
    }
  }

