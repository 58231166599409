.Dsh-box {
    box-sizing: border-box;
    width: 290px;
    height: 184px;
    background: rgba(217, 217, 217, 0.58);
    margin: 15px;
    border: 1px solid white;
    box-shadow: 12px 17px 51px rgba(0, 0, 0, 0.12);
    border-radius: 17px;
    text-align: center;
    cursor: pointer;
    transition: all 0.5s;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    font-weight: bolder;
    color: black;
  }
  
  .Dsh-box:hover {
    border: 1px solid black;
    transform: scale(1.02);
  }
  
  .Dsh-box:active {
    transform: scale(0.95) rotateZ(1.7deg);
  }

.AdminSummery th{
    background-color: #6b727aa2 !important;
    font-size: small;
}