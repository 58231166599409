.crd-patch{
    align-items: center;
    border: none !important;
}
.crd-patch-img{
    width: 130px;
    height: 130px;
    align-self: center;
    margin-bottom: -25px;
    border-radius: 50%;
    padding: 30px;
    background-color: #3198D4;

}
.crd-patch-tit{
    margin-top: -15px;
    text-align: center;
    size: 50px;
}
.crd-patch-tit .btn-primary{
    background-color: #1E194E;
    border-color: #1E194E;
    width: 200px;
    padding: 15px 0px 15px 0px;
}
.crd-patch-tit .btn-primary:hover{
    background-color: #0a53be;
}
.crd-patch-tit .btn-primary:active{
    background-color: #0a53be;
}

.crd-patch-body{
    background-color: #3198D4;
    border-radius: 30px;
    padding: 30px;
    margin-top: -27px;
    color: aliceblue;
    width: 270px;
    height: 160px;
}
.card-btan{
    text-align: center;
}