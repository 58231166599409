.letsWork {
    margin-top: 130px !important;
    display: flex !important;
    justify-content: center !important;
  align-items: center !important;
    height: 80vh !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-blend-mode: multiply !important;
    background-image: url('/public/assets/AboutLetsWork.webp'); /* Replace with your image path */
  
    
  }
  .btn-primary{
    border-color: #54429A !important;
  }
  
  
/* Add media queries for different screen sizes */
@media only screen and (max-width: 767px) {
.letsWork {
    height: 35vh !important;
    background-size:cover !important;
    }
.letsWork-cont h1{
    width: 60vw !important;
    font-size: 28px !important;
    margin-bottom: -30px !important;
}
.letsWork-cont a{
    
    font-size: 14px !important;
    width:max-content !important;
}
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .letsWork {
        height: 35vh!important;
    }
}

@media only screen and (min-width: 1024px) {
    .letsWork {
        height:80vh ;
    }
}