.why-digi-cntainer{
    background-color: #f5f5f675;
}

ol {
    font-size: 16px;
    margin-top: 0;
    margin-bottom: 1rem;
}
li{
    margin-bottom: 14px;
}
@media only screen and (max-width: 768px) {
  .gelry{
    display: none;
  }
}
.gel-row{
margin-top: 20px;
margin-left: 60px;

}
.why-digi-gel-1{
margin-right: 20px;
}
.why-digi-gel-2{
margin-right: 20px;
margin-top: -67px;

}
.why-digi-gel-3{
    margin-top: 20px;
}
.why-digi-gel-4{
    margin-right: 20px;

}
.why-digi-gel-5{
    margin-right: 20px;
    margin-top: -140px;

}
.why-digi-gel-6{
margin-top: -50px;
}
.why-digi-gel-7{
margin-top: -60px;
margin-left: 194px;


}

/* .groupDiv {
    position: absolute;
    top: 1px;
    left: 750px;
    width: 845px;
    height: 720px;
  }
  
  .rectangleIcon {
    left: 0;
    width: 345px;
    height: 345px;
    object-fit: cover;
  }
  .groupChild1,
  .rectangleIcon {
    position: absolute;
    top: 0;
    border-radius: var(--br-11xl);
  }
  .groupChild1 {
    left: 375px;
    background-color: var(--color-midnightblue);
    width: 220px;
    height: 220px;
  }
  .groupChild2 {
    top: 0;
    left: 375px;
    object-fit: cover;
    opacity: 0.7;
  }
  .groupChild2,
  .groupChild3,
  .groupChild4,
  .groupChild5 {
    position: absolute;
    border-radius: var(--br-11xl);
    width: 220px;
    height: 220px;
  }
  .groupChild3 {
    top: 250px;
    left: 375px;
    object-fit: cover;
  }
  .groupChild4,
  .groupChild5 {
    top: 375px;
    left: 125px;
  }
  .groupChild4 {
    background-color: var(--color-midnightblue);
  }
  .groupChild5 {
    object-fit: cover;
    opacity: 0.3;
  }
  .groupChild6 {
    top: 500px;
    left: 375px;
    background-color: var(--secondary);
  }
  .groupChild10,
  .groupChild6,
  .groupChild7,
  .groupChild8,
  .groupChild9 {
    position: absolute;
    border-radius: var(--br-11xl);
    width: 220px;
    height: 220px;
  }
  .groupChild7 {
    top: 500px;
    left: 375px;
    object-fit: cover;
    opacity: 0.8;
  }
  .groupChild10,
  .groupChild8,
  .groupChild9 {
    left: 625px;
  }
  .groupChild8 {
    top: 125px;
    background-color: var(--secondary);
  }
  .groupChild10,
  .groupChild9 {
    object-fit: cover;
  }
  .groupChild9 {
    top: 125px;
    opacity: 0.5;
  }
  .groupChild10 {
    top: 375px;
  } */