.orderId{
    font-size: 10px;
    padding: 0px;
    margin: 0px;
}
.recordImg img{
    padding: 0px;
    margin: 0px;
    height: 50px;
    width: 50px;
   }
