@media (max-width: 767px) {
  
  .img-szee{
    width: 150px !important;
        height: 150px !important;
        transition: transform 0.3s, box-shadow 0.3s, filter 0.3s !important;
        opacity: 1 !important;
        transform: scale(1) !important;
        position: relative !important;
        z-index: 1 !important;
  }
 
  .img-szee:hover{
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
        opacity: 2.2;
        transform: scale(1.0) rotate(5deg);
        z-index: 4; 
  }
 
  .secnd-hding {
    display: none;
  }
}

    .portflo-cntainer {
        margin-top: 150px;
      }
      
      .heding {
        margin-bottom: -45px;
      }

      .imag-sze {
        width: 200px;
        height: 200px;
        transition: transform 0.3s, box-shadow 0.3s, filter 0.3s;
        opacity: 1;
        transform: scale(1);
        position: relative;
        z-index: 1;
      }
      
      .imag-sze:hover {
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
        opacity: 2.2;
        transform: scale(1.1) rotate(0deg);
        z-index: 4; 
      }
      
    

      


      