.p-cntainer{
  margin-top: 8rem;
  margin-bottom: 5rem;
}
.h2-orpricing {
    text-align: center;
    font-size: 42px;
    font-weight: 700;
}
.crd-row{
    align-items: center;
    display: flex;
    justify-content: center;
}
.crd-pricing{
    background-color: #fff;
    border-radius: 30px !important;
    width:max-content !important;
    height:max-content !important;
    margin-top: 20px !important;
    margin-bottom: 20px !important;
    margin-inline: 20px !important;
    box-shadow: 0px 0px 20px 4px rgba(28, 52, 84, 0.25);
}
span.color-1 {
    color: rebeccapurple;
}
.list-group-item{
    border: 0 !important;
}
.crd-pricing.second-item .card-title,
.crd-pricing.second-item .list-group-item {
  color: #F9F9F9;
}

.crd-pricing.second-item .populer .batn{
  color: #F9F9F9;
  font-size: 16px;
  /* padding: 30px; */
  /* align-items: center; */
  /* align-self: center; */
  /* align-content: center; */
  /* text-align: center; */
  width: 140px;
  padding: 5px;
  background-color: #54429A;
  border-radius: 30px;
  
}
/* @media only screen and (max-width: 1226px) {
    .col-md-3 {
      width: 50%; 
    }
  } */
  

  

.crd-pricing.second-item{
  background-color: #3198D4; /* Replace with your desired background color */
transform: scale(1);
}
.crd-pricing.second-item .list-group-item{
    background-color: #3198D4;
}
.list-group-item path{
    color: #1C3454;
}

/* Position the control icons below the.mob-prizList  carousel */
.mob-prizList .carousel-control-prev,
.mob-prizList .carousel-control-next {
  top: 100% !important; /* Position below the.mob-prizList  carousel */
  margin-top: 10px !important; /* Add some space between the.mob-prizList  carousel and the icons */
}
.mob-prizList .carousel-control-prev-icon{
  margin-right: -60vw !important;
}
.mob-prizList .carousel-control-next-icon{
  margin-left: -60vw !important;
}

/* Center the control icons horizontally */
.mob-prizList .carousel-control-prev-icon,
.mob-prizList .carousel-control-next-icon {
  display: inline-block !important;
  width: 30px !important; /* Set the desired width of the icon */
  height: 30px !important; /* Set the desired height of the icon */
  background-color: #333 !important; /* Set the desired background color of the icon */
  border-radius: 50% !important; /* Make it circular */
  /* Add any other styles you want for the icon */
}

/* Optional: Adjust the icon color when hovering over it */
/* .mob-prizList .carousel-control-prev:hover .mob-prizList .carousel-control-icon,
.mob-prizList .carousel-control-next:hover .mob-prizList .carousel-control-icon {
  background-color: #555;
} */



  @media only screen and (min-width: 768px) {
    .mob-prizList {
      display: none !important;
    }
  }
  @media only screen and (max-width: 768px) {
    .desk-prizList{
      display: none !important;
    }
    .p-cntainer{
      margin-top: 50px;
    }
    .h2-orpricing{
      font-size: 36px;
    }
    /* .crd-pricing.col-sm-12 {
      width: 90%;
      --bs-gutter-x: 0px;
    } */
    .crd-pricing.second-item{
      transform: scale(1);
    }

    .testi-head {
      text-align: center !important;
    }

    .crd-pricing.second-item {
      background-color: #3198D4;
      /* Replace with your desired background color */
      transform: scale(1) !important;
    }
  }
  
  