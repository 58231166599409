
.testi-cntainer{
    width: 97vw !important;
}
.testi-crd{
    border-radius: 20px !important;
    height:200% !important;
}
.testimonal-body-txt{
    height: 4rem;
}
.testi-footer{
    background-color: #3198D4;
    color: whitesmoke;
    align-self: center;
    padding-left: 10px;
    padding-top: 5px;
    width: 200px;
    height: 60px;
}
.testi-footer .h6{
    font-size: 14px;
}
.testi-footer .h5{
    font-size: 16px;
}

.clrcl{
    background-color: #ffff;
    width: 150px;
    height: 150px;
    padding: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; 
    border-radius: 50%;
   
}

.clrcl p {
    font-size: 12px;
    color: #1d242eab;
}
@media only screen and (max-width: 768px) {
    .testi-slid-col.col-sm-12 {
        width: 96%;
    }
    .testi-head{
      text-align: center;  
    }
}