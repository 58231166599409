.detail-container {
    margin-top: 50px;
    /* padding: 1.5rem 0; */
    border: none;
}
.roaw {
    margin-top: 3.5rem;
    display: flex;
}
.rght-side {
    font-size: 22px;
    padding-left: 20px;
}
.detail-container .col-12{
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

}
/* .roaw-revrs{
    display: grid;
} */
