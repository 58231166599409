  /* order's section */

  .dshh-crdss {
    position:relative;
    width: 200px;
    height: 200px;
    box-shadow: 0px 1px 13px rgba(0,0,0,0.3);
    /* cursor: pointer; */
    /* transition: all 120ms; */
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    padding: 0.5em;
    padding-bottom: 3.4em;
  }
  
  .dshh-crdss::after {
    content: "Click Here!";
    padding-top: 1.25em;
    padding-left: 1.25em;
    position: absolute;
    left: 0;
    /* bottom: -60px; */
    background: #000000;
    color: #fff;
    height: 50px;
    width: 100%;
    /* transition: all 80ms; */
    font-weight: 600;
    text-transform: uppercase;
    opacity: 0;
  }
  
  .dshh-crdss .title {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.9em;
    position: absolute;
    /* left: 0.625em; */
    /* bottom: 1.875em; */
    font-weight: 400;
    color: #000;
  }
  
  .dshh-crdss .price {
    font-family:'Arial Narrow Bold', sans-serif;
    font-size: 16px;
    position: absolute;
    left: 0.625em;
    bottom: 0.625em;
    color: #000;
  }
  
  .dshh-crdss:hover::after {
    bottom: 0;
    opacity: 1;
  }
  
  /* .dshh-crdss:active {
    transform: scale(0.98);
  } */
  
  .dshh-crdss:active::after {
    content: "You Clicked it !";
    height: 3.125em;
  }
  
  .dshh-crd-immg {
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
    border-color: #000000;
  }