.digitiz-cont{
    width: 500px;
    height: 500px;
    text-align: start;
}
.digi-cont{
    display: flex;
    align-items: center;
    justify-content: center;
}
.digitiz-cont p{
    font-size: larger;
    text-align: left;
}
.Digitiz-gellary, .digi-img{
    width: 500px;
    height: 500px;
    border-radius: 30px;
}
.v-gellary{
    width: 30rem;
    height: 60rem;
}
@media only screen and (max-width: 768px) {
    
    .desk-d-gel{
        display: none;
    }
    .mob-d-imag img{
        margin-top: 40px;
        width: 330px;
        height: 330px;
    }
    .digitiz-cont{
        width: 381px;
        height: 380px;
        text-align: center;
    }
    .digitiz-cont p{
        font-size: medium;
        text-align: center;
    }
  }
@media only screen and (min-width: 768px) {
    .mob-d-imag{
        display: none;
    }

}