.a-Header{
    background-image: url("/public/assets/VectorHeader.webp"),linear-gradient(180deg, rgba(21, 45, 64, 0.00) 0%, rgba(30, 25, 78, 0.46) 25.43%, #625f83 100%);
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-blend-mode: multiply !important;
    margin-bottom: 5vh !important;
    clip-path: polygon(50% 0%, 100% 0, 100% 90%, 78% 100%, 0 90%, 0 0);
    display: flex;
    height: 55vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  /* .a-Header.shrink{
      height: 200px;
  } */
  /* .a-Header-contnt{
      display: flex !important;
      flex-direction: column !important;
      align-items: center !important;
      justify-content: center !important;
      position: relative !important;
      top: 10vh !important;
  } */
  /* Add media queries for different screen sizes */
  /* @media only screen and (max-width: 768px) {
    .a-Header{
      height: 40vh !important;
      padding-bottom: 40px !important;
      margin-bottom: 10vh !important;
    }
    .a-Header-contnt{
      top:0vh !important;
    }
  } */
  /* @media screen and (max-width: 767px) {
    
    
    
  } */
  
  /* @media screen and (min-width: 768px) and (max-width: 1023px) {
    .a-Header{
    height: 40vh !important;
    }
  }
  
  @media screen and (min-width: 1024px) {
    .a-Header{
      height: 50vh !important;
    }
  }
    */