.registr-raow{
    /* margin-top: -13vh !important; */
}

.registr-containr {
    border: 2px solid #54429A !important;
    border-radius: 30px !important; 
    margin-top: 8.6rem;
    margin-bottom: 3rem;
  }

  .regsform-cntrol{
    border: 1px solid #54429A !important;
    border-radius: 6px !important;
    background-color: white !important;

  }

.registr-form{
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    /* height:750px; */
    height: 100vh !important;
    padding: 50px !important;
    background-color: #fff !important;
    margin-top: 100px !important;
}
.registr-form .btn{
    width: 100px !important;
    font-size: 14px !important;
}
.registr-rght{
    background-image: url('/public/assets/Login.webp') !important;
    background-size: cover !important;
    background-color: #3198D4 !important;
    /* height:750px; */
    height: 114vh !important;
    
    display: flex !important;
    flex-direction: column !important;
    justify-content: flex-end !important;
    color:#fff !important;
    padding: 50px !important;
}




@media (max-width: 767.98px) {
    .registr-raow {
        flex-direction: column-reverse !important;
    }
    .registr-rght{
        height: 40px !important;
    }
    .registr-rght h1,
    .registr-rght .btn{
        display: none !important;
    }
    .registr-form{
        height: auto !important;
    }

}