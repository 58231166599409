.hero-section {
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("/public/assets/patchHeader.webp"),linear-gradient(180deg, rgba(21, 45, 64, 0.00) 0%, rgba(30, 25, 78, 0.46) 25.43%, #625f83 100%);
  /* linear-gradient(white , #5A5E87); */
  background-repeat: no-repeat;
  background-blend-mode: multiply;
  background-size: cover;
  height:770px;
  /* margin-bottom: 10vh; */
  clip-path: polygon(50% 0%, 100% 0, 100% 90%, 78% 100%, 0 90%, 0 0);
  /* position: absolute;
      top: 0;
      left: 0;
      z-index: 1; */
}
.container-form{

    width: 100%;
    border-radius: 30px;
    /* margin-top: 35rem; */
    font-size: 14px;
    bottom: 0 !important;
}
.hero-form-left{
    border-radius: 30px 0px 0px 30px;
    height: 500px;
    padding: 30px;
    background-color: white;
}
.hero-form-left a{
  text-decoration: none;
  color: black;
  margin-top: -40px;

}
.hero-form-right{
  background-image: url("/public/fox.webp");
    background-position-x: right;
    background-position-y: bottom;
    background-repeat: no-repeat;
    background-size: 400px;
    height: 500px;
    padding: 30px;
    /* padding-top: 110px; */
    border-radius: 0px 30px 30px 0px;
    background-color:#3198D4;
    text-align: center;
    color: aliceblue;
}
.hero-form-right img{
 width: 7rem;
 height: 7rem;
 alt: 'QR-code';
}

.btn-primary{
  background-color:#54429A !important;
  width: 150px !important;
  border-radius: 25px !important;
  border: none !important;
}

  .form-control{
    border-width: 0px 0px 1px 0px;
    border-radius: 0px;
    font-size: 12px;
    border-color: rgba(0, 0, 0, 0.816);
  }
  .form-select{
    border-width: 0px 0px 1px 0px;
    border-radius: 0px;
    font-size: 12px;
    width: 120px;
    border-color: rgba(0, 0, 0, 0.816);
  }
  .hero-slider{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30rem;
    height: 30rem;
  }
  .hero-slider .carousel-indicators{
    display: none !important;

  }


/* SOCIAL MEDIA HOVER EFFECT CSS CODING  */
  .fb-Hvr:hover{
    color: #1877f2 !important;
  }

  .twit-Hvr:hover{
    color: #1DA1F2 !important;
  }
  .utube-Hvr:hover{
    color: #FF0000 !important;
  }
  .h-discord{
    margin-top: -15vh;
    margin-right: 2vh;
  }
  .insta-Hvr {
    text-decoration: none !important; 
    color: white !important;
    transition: color 0.3s !important;
  }
  
  .insta-Hvr:hover {
    color: #e4405f !important;
  }

  .linkedin-Hvr {
    transition: color 0.3s ease !important;
  }
  
  .linkedin-Hvr:hover {
    color: #0A66C2 !important;
  }
  .tiktok-Hvr {
    text-decoration: none !important; 
    transition: color 0.3s !important; 
    color: white !important;
  }
  
  .tiktok-Hvr:hover {
    color: #000000 !important; 
  }
  .instQ-btn{
    margin-bottom: -7rem;
    /* display: flex;
    align-items: center;
    justify-content: center; */
    /* align-self: center; */
    justify-self: center;
  }
  /* SOCIAL MEDIA HOVER EFFECT CSS CODING IS END HERE  */
  @media screen and (min-width: 992px) {
    .instQ-btn{
      display: none !important;
    }

  }
 @media screen and (max-width: 992px) {
  .hero-section {
    display: grid;
    align-items: end;
    background-size: cover;
    height: 40vh !important;
    padding-bottom: 10vh;
  }
  .checkIn{
    display: none !important;
  }
  
}