.abD-cntainer{
    background-image: url('/public/AboutMap.webp');
    background-size:contain !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    width: 100vw !important;
    height: 100vh !important;
    margin-top: -10vh !important; 

}
.abD-content{
    margin-top: -30vh !important;
    width: 70vw !important;
    text-align: center !important;
    font-size: larger !important;
}
.abD-content h1{
    margin-bottom: 7vh !important;
    font-size: 40px !important;
    font-weight:600 !important;
}


/* Fox & Gellery Section */
.abD-Fox{
    background-image: url('/public/fox.webp') !important;
    background-repeat: no-repeat !important;
    background-position: bottom !important;
    background-position-x: right !important;
    background-size: 400px !important;
    background-color: #3198D4 !important;
    color: whitesmoke !important;
    padding: 50px !important;
    border-radius: 30px 30px 0px 30px !important;
    font-size: 26px !important;
    text-align: center !important;
}
.abD-gellry img{
    width: 32vw !important;

}


@media (max-width: 1199.98px) { 
    .abD-Fox{
        font-size: 18px !important;
    }
 }
@media (max-width: 991.98px) {
    .abD-Fox{
        font-size: 14px !important;
        padding: 30px !important;
    }
}


@media (max-width: 767.98px) { 
    .abD-cntainer{
        align-items:flex-start !important;
        background-position: top !important;
        margin-top: 5vh !important;
    }
    .abD-content{
        width: 91vw !important;
        margin-top: 10vh !important;
    }
    .abD-content h1{
        margin-bottom: 80px !important;
        font-size:34px !important;
    font-weight: 600 !important;
    }
    .Fox-col.col-12{
        --bs-gutter-x: auto !important;
    }
    .abD-Fox{
        margin-top: 250px !important;
        font-size: 16px !important;
        border-radius: 0px !important;
        padding: 40px 20px 40px 20px !important;
    }
    .abD-gellry{
        display: none !important;
    }
}
