.crd{
    align-items: center;
    border: none !important;
}

.crd-img{
  
    width: 200px;
    height: 200px;
    align-self: center;
    margin-bottom: -30px;
}
.crd-tit{
    margin-top: -15px;
    text-align: center;
}

.btn-primary {
  background-color: #54429A;
  width: 150px;
  border-radius: 30px;
  border: none;
}

.crd-tit .btn-primary{
    background-color: #1E194E;
   
}
.crd-tit .btn-primary:hover{
    background-color: #0a53be;
}
.crd-tit .btn-primary:active{
    background-color: #0a53be;
    
}

.crd-body{
    background-color: #3198D4;
    border-radius: 30px;
    padding: 30px;
    margin-top: -27px;
    color: aliceblue;
    width: 300px;
    height: 245px;
}
.card-btan{
    text-align: center;
}

/* Mobile Slider */
.mobile-slider {
    display: none; /* Hide on desktop */
  }
  
  /* Desktop Cards */
  .desktop-cards {
    display:flex; /* Display on desktop */
    
  }
  
  @media (max-width: 767px) {
    /* Display mobile slider on screens less than 768px */
    .mobile-slider {
      display: block;
    }
  
    /* Hide desktop cards on screens less than 768px */
    .desktop-cards {
      display: none;
    }
  }


  /* Ipad view */

  @media screen and (min-width: 768px) and (max-width: 1024px),
(min-width: 820px) and (max-width: 1080px) {
  .mobile-slider {
    display: block;
    padding: 180px;
    margin-top: -20%;
  }

  /* Hide desktop cards on screens within the specified ranges */
  .desktop-cards {
    display: none;
  }
}


@media (max-width: 280px) {
  .mobile-slider {
    display: block;
 width: 100%;
 justify-content: center;
  }
  .crd-body{
    background-color: #3198D4;
    border-radius: 30px;
    padding: 20px;
    margin-top: -27px;
    color: aliceblue;
    width: 230px;
    height: 235px;
}

  /* Hide desktop cards on screens within the specified ranges */
  .desktop-cards {
    display: none;
  }

}