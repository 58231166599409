.error_msg {
	width: 370px !important;
	padding: 15px !important;
	margin: 5px 0 !important;
	font-size: 14px !important;
	background-color: #f34646 !important;
	color: white !important;
	border-radius: 5px !important;
	text-align: center !important;
}
.logn-raow{
    margin-top: -15.7vh !important;
}

.lgnform-cntrol{   
        border: 1px solid #54429A !important;
        border-radius: 6px !important;
        background-color: white !important;
}

.logn-form{
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    /* height:750px; */
    height: 100vh !important;
    padding: 50px !important;
    background-color: #fff !important;
}
.logn-form .btn{
    width: 100px !important;
    font-size: 14px !important;
}
.logn-rght{
    background-image: url('/public/assets/Login.webp') ;
    background-size: cover !important;
    background-color: #3198D4;
    /* height:750px; */
    height: 107.7vh !important;
    
    display: flex !important;
    flex-direction: column !important;
    justify-content: flex-end !important;
    color:#fff !important;
    padding: 60px !important;
}
@media (max-width: 767.98px) {
    .logn-raow {
        flex-direction: column-reverse !important;
    }
    .logn-rght{
        height: 200px !important;
    }
    .logn-rght h1,
    .logn-rght .btn{
        display: none !important;
    }
    .logn-form{
        height: auto !important;
    }

}