.outr-container {
    background-color: rgb(247, 247, 247); 
  }
  
  .innr-container {
    border: 2px solid #54429A;
    border-radius: 30px; 
  }
  
  .dgOrd-frm-logo{
    width: 70px;
  }

  .frm-dshline {
    flex-grow: 1;
    height: 3px;
    background-color: #54429A;
    margin: 0px;
  }
  
  .colorRadio{
    display: flex;
    font-size: 12px;
  }

  .colorRadio .form-check{
    margin-right: 2px;
  }


.dgform-cntrol{
    border: 1px solid #54429A !important;
    border-radius: 6px !important;
    background-color: white !important;
}


 