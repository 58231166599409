.Booknow {
    margin-top: 130px;
    display: flex;
    justify-content: center;
  align-items: center;
    height:80vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-blend-mode: multiply;
    background-image: url('/public/assets/BookNow.webp'); /* Replace with your image path */
  
    
  }
  .btn-primary{
    border-color: #54429A;
  }
  
  
/* Add media queries for different screen sizes */
@media screen and (max-width: 767px) {
.Booknow {
    height: 35vh;
    }
.booknow-cont h1{
    
    font-size: 32px;
    font-weight: 600;
}
.booknow-cont a{
    
    font-size: 12px;
    width:max-content;
}
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
    .Booknow {
        height: 35vh;
    }
}

@media screen and (min-width: 1024px) {
    .Booknow {
        height:80vh;
    }
}