.section{
    margin-top: 50px;
    /* --primary-gradient: linear-gradient(45deg, #3198D4 0%, #1E194E 100%);
    background: var(--primary-gradient); */
    color: #fff;
    background: #3198D4 ;
}
.inner-cntainer{
    margin-top: 100px;
}

.grid-box-com{
    margin-top: 60px;
}
.grid-box-1 {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    width: 340px;
    height: 250px;
    /* vertical-align: middle; */
}

.grid-box{
    font-size: 12px;
    padding-left: 20px;
    width: 340px;
    height: 250px;
    border: 1px solid #F9F9F9;
    border-radius: 30px;
}
.grid-box img{
    margin-top: -40px;
    /* margin-left: -230px; */
    width: 60px;
    height: 60px;
    background-color: #3198D4;
}
.grid-box-com p{
    font-size: 18px;
}
.fox{
    background-image: url("/public/fox.webp");
    background-position-x: right;
    background-position-y: bottom;
    background-repeat: no-repeat;
    background-size: 800px;
}
@media only screen and (max-width: 768px) {
   
   
    .grid-box img{
        margin-top: -11vw;
        margin-left: 0px;
    }
    .fox{
        background-size: 300px;
    }
    .grid-box-com p{
        text-align: left;
    }
  
    .grid-box{
        font-size: 12px;
        padding-left: 20px;
        width: 330px;
        height: 250px;
        border: 1px solid #F9F9F9;
        border-radius: 30px;
    }

  }

 /* Add this media query to hide the slider on desktop */
@media (min-width: 768px) {
    .mbile-slidr {
      display:none;
    }
  }

  /* ye desktop wala section mobile sy hataya hai isiliye ye media querry use kiya hai */
  @media (min-width: 320px) and (max-width: 767px) {
    .dskt-OurFetur-off {
            display: none;
    }}




    /* ipad view */
    @media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
        .grid-box{
            font-size: 10px;
            padding-left: 10px;
            width: 200px;
            height: 260px;
            border: 1px solid #F9F9F9;
            border-radius: 30px;
        }
        .grid-box img{
            margin-top: -50px;
            /* margin-left: -110px; */
        }
      }

      /* surface duo view */

      @media screen and (min-device-width: 540px) and (max-device-width: 720px) {
        .grid-box{
            font-size: 10px;
            padding-left: 10px;
            width: 480px;
            height: 260px;
            border: 1px solid #F9F9F9;
            border-radius: 30px;
        }
        .grid-box img{
            margin-top: -50px;
            /* margin-left: -110px; */
        }


        /* extra small device view like glaxy fold */
        @media screen and (min-width: 280px) and (max-width: 653px) {
            .mbile-slidr {
              display: none;
            }
    }}