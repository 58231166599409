.Sngle-recrd-details{
    background-color:rgb(247, 247, 247);
    font-size: 17px;
}

.innr-continer {
    border: 2px solid #54429A;
    border-radius: 30px; 
  }

  .frm-dashline{
    flex-grow: 1;
    height: 3px;
    background-color: #54429A;
    margin: 0px;
    position: blocked;
  }

  .frm-verticl-dashline{
    flex-grow: 1;
    height: 3px;
    background-color: #54429A;
    margin: 0px;
  }

  .sngleRcord-Border{
    border: 2px solid #54429A ;
    border-radius: 5px 0px 0px 5px ;
    background-color: rgb(245, 245, 245) ;
    font-size: 12px;
    
    
  }

  /* .sngleRcord-Borde {
    border-right: 2px solid #54429A;
    border-top: 2px solid #54429A;
    border-bottom: 2px solid #54429A;
    border-left: none;
    border-radius: 0 5px 5px 0;
    background-color: white;
   
  } */
  